<template>
    <div class="container">
        <div class="row align-items-center my-5">
            <div class="col-md-6">
                <div class="col-md-6">
                    <h1>Dear Customer.</h1>
                    <h2 class="font-weight-normal">Finish your registration. <br> Set a password.</h2>
                </div>
            </div>
            <div class="col-md-4 small-form-bg">
                <b-form @submit.prevent="newPasswordSubmit()" class="mt-4">
                    <input type="hidden"
                           v-model="credentials.token">

                    <b-form-group>
                        <b-form-input type="password"
                                      id="password"
                                      name="password"
                                      :placeholder="$t('new_password')"
                                      ref="password"
                                      v-model="credentials.password"
                                      v-validate="'required|min:4'"
                                      :data-vv-as="$t('password')"
                                      data-vv-validate-on="none"
                                      size="lg"
                                      class="form-control"
                                      :class="{'is-danger': errors.has('password') }">
                        </b-form-input>
                        <small v-show="errors.has('password')" class="text-danger is-danger">
                            <i class="fa fa-warning"></i> {{ errors.first('password') }}
                        </small>

                        <b-form-input type="password"
                                      id="password2"
                                      name="password2"
                                      :placeholder="$t('confirm_password')"
                                      v-model="credentials.password2"
                                      v-validate="'required|confirmed:password'"
                                      :data-vv-as="$t('confirm_password_field')"
                                      data-vv-validate-on="none"
                                      size="lg"
                                      class="form-control mt-3"
                                      :class="{'is-danger': errors.has('password2') }">
                        </b-form-input>
                        <small v-show="errors.has('password2')" class="text-danger is-danger">
                            <i class="fa fa-warning"></i> {{ errors.first('password2') }}
                        </small>
                    </b-form-group>


                    <div class="mt-3">
                        <b-button type="submit"
                                  variant="warning"
                                  size="lg"
                                  :disabled="newPasswordSubmitted">
                            {{ $t('save_password') }}
                        </b-button>
                    </div>

                    <div class=" mt-4">
                        <router-link to="/login">
                            « {{ $t('back') }}
                        </router-link>
                    </div>
                </b-form>

            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import Vue from 'vue';
    import { apiClient } from '@/util/resources';

    export default Vue.extend({
        data() {
            return {
                credentials: {
                    token: '',
                    password: '',
                    password2: '',
                },
                newPasswordSubmitted: false,
            };
        },

        methods: {

            newPasswordSubmit() {
                this.newPasswordSubmitted = true;

                this.$validator.validateAll().then(result => {
                    if (result) {
                        (async () => {

                            let formData = new FormData();

                            _.forEach(this.credentials, (val, key) => {
                                formData.append(key, val === null ? '' : val);
                            });

                            await apiClient.post('api/registration/confirm', formData).then(response => {
                                this.$root.$bvToast.toast('Registration successful.', {
                                    title: 'Sikeres',
                                    variant: 'success',
                                    solid: true,
                                    toaster: 'b-toaster-top-center',
                                });

                                this.$router.push({ name: 'login' });
                            }).catch(error => {
                                this.$root.$bvToast.toast(error.message, {
                                    title: this.$i18n.t('_error'),
                                    variant: 'danger',
                                    solid: true,
                                    noAutoHide: true,
                                    toaster: 'b-toaster-top-center',
                                });
                            });

                            this.newPasswordSubmitted = false;
                        })();
                    } else {
                        this.newPasswordSubmitted = false;
                    }
                });
            },
        },

        created() {
            this._debug('created token');
            if (this.$route.params.token) {
                this.credentials.token = this.$route.params.token;
            }
        },

    });
</script>

<style lang="scss">
    .bg-left {
        background: radial-gradient(ellipse at bottom, #00549c 0%, #0089ff 100%);;
        background-attachment: fixed;
        color: hsla(207, 20%, 95%, 1);
    }
</style>
